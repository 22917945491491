import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLinkedin, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faAt } from '@fortawesome/free-solid-svg-icons'
import LangRouter from "./components/Router/Lang";
import NoMatch from "./components/Router/NoMatch";
import { Provider } from 'react-redux'
import configureStore from './configureStore'

library.add(faLinkedin, faFacebook, faTwitter, faAt)

const store = configureStore();

export default class App extends Component {

  render() {

    return (
      <Provider store={store} >
        <Router>
          <Switch>
            <Route path="/:locale" component={LangRouter} />
            <Route component={NoMatch} />
          </Switch>
        </Router>
      </Provider>
    );

  }

}
