import en from '../lang/en';
import es from '../lang/es';
import fr from '../lang/fr';

const messages = { en, es, fr };

const lang = (state, action) => {
    if (!state) {
        let locale = 'en';
        let localeMessages = messages.en;
        const storedLocale = localStorage.getItem('locale')
        if(storedLocale && messages[storedLocale]) {
            locale = storedLocale;
            localeMessages = messages[storedLocale];
        }
        state = {
            locale,
            messages: localeMessages,
            locales: [
                { key: 'en', label: 'English' },
                { key: 'fr', label: 'Français' },
                { key: 'es', label: 'Español' },
            ]
        }
    }
    switch (action.type) {
        case 'SET_LOCALE':
            if (messages[action.locale]) {
                state.locale = action.locale;
                state.messages = messages[state.locale];
                localStorage.setItem('locale', action.locale)
            }
            return state;
        default:
            return state;
    }
}

export default lang;
