import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux'
import { generateUrl } from '../actions/lang';
import { Link } from 'react-router-dom';

class Footer extends Component {

    render() {
        return (
            <div className="footer">
                <div className="footer-container container-fluid">
                    <div className="row">
                        <div className="col-6">
                            <div className="footer-link">
                                <Link to={this.props.generateUrl(`/gcu`)} >
                                    <FormattedMessage id={`footer.gcu`} />
                                </Link>
                            </div>
                            <div className="footer-separator"></div>
                            <div className="footer-link">
                                <Link to={this.props.generateUrl(`/legal`)} >
                                    <FormattedMessage id={`footer.legal`} />
                                </Link>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="footer-text">
                                © AjouveLimited 2020
                             </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => ({
    generateUrl: generateUrl(state)
})

export default injectIntl(connect(mapStateToProps, {})(Footer));