import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Helmet } from "react-helmet";
import { faInstagram } from "@fortawesome/free-brands-svg-icons"

import subforceLogo from '../img/subforce/logo.png'
import subforcePresentation from '../img/subforce/presentation.png'

import deneuvilleLogo from '../img/deneuville/logo.png'
import deneuvillePresentation from '../img/deneuville/presentation.png'

import holoperfLogo from '../img/holoperf/logo.png'
import holoperfPresentation from '../img/holoperf/presentation.png'

import ijkoaLogo from '../img/logo.png'
import ijkoaPresentation from '../img/subforce/presentation.png'

const images = {
    subforce: {
        logo: subforceLogo,
        presentation: subforcePresentation
    },
    deneuville: {
        logo: deneuvilleLogo,
        presentation: deneuvillePresentation
    },
    holoperf: {
        logo: holoperfLogo,
        presentation: holoperfPresentation
    },
    ijkoa: {
        logo: ijkoaLogo,
        presentation: ijkoaPresentation
    }
}

class Company extends Component {

    renderSocialAdditionnalLink(){
        if (!this.props.company.social.additional) return;
        return (
            <a className="company-element-social-icon" href={this.props.company.social.additional} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={'at'} />
            </a>
        )
    }

    renderTwitterLink(){
        if (!this.props.company.social.twitter) return;
        return (
            <a className="company-element-social-icon" href={this.props.company.social.twitter} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={['fab', 'twitter']} />
            </a>
        )
    }

    renderInstagramLink(){
        if (!this.props.company.social.instagram) return;
        return (
            <a className="company-element-social-icon" href={this.props.company.social.instagram} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} />
            </a>
        )
    }

    renderViewLink(){
        if (!this.props.company.link) return;
        return (
            <div className = "company-element-view" >
                <a className="company-element-button" href={this.props.company.link} target="_blank" rel="noopener noreferrer"><FormattedMessage id={`company.${this.props.company.reference}.view.button`} /></a>
            </div>
        )
    }

    render() {
        return (
            <div className={`company-element ${this.props.company.reference}-element`}>
                <Helmet>
                    <title>{`${this.props.intl.formatMessage({ id: `company.${this.props.company.reference}.meta.title`})}`}</title>
                    <meta name="description" content={this.props.intl.formatMessage({ id: `company.${this.props.company.reference}.meta.description`})} />
                </Helmet>
                <div className="company-element-circle-container">
                    <div className="company-element-circle">
                        <div className="company-element-image">
                            <img src={images[this.props.company.reference].presentation} alt="Subforce" className="img-fluid" />
                        </div>
                        <div className="company-element-description">
                            <img src={images[this.props.company.reference].logo} alt="logo Subforce" className="img-fluid company-element-logo" />
                            <p className="company-element-head-text" ><FormattedMessage id={`company.${this.props.company.reference}.head.text`} /></p>
                            <p className="company-element-text" ><FormattedMessage id={`company.${this.props.company.reference}.text`} /></p>
                            <div className="company-element-social" >
                                <a className="company-element-social-icon" href={this.props.company.social.facebook} target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={['fab', 'facebook']} />
                                </a>
                                {this.renderTwitterLink()}
                                <a className="company-element-social-icon" href={this.props.company.social.linkedin} target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={['fab', 'linkedin']} />
                                </a>
                                {this.renderInstagramLink()}
                                {this.renderSocialAdditionnalLink()}
                            </div>
                            {this.renderViewLink()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default injectIntl(Company)