import React, { Component } from 'react'
import Logo from '../img/logo.png'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import { generateUrl, getLocales, getLocale } from '../actions/lang';
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl';

class Header extends Component {

    renderMenuItems() {
        return this.props.getLocales().map(locale => (<NavDropdown.Item
            key={locale.key}
            onClick={() => this.handleLangChange(locale.key)}
        >
            {locale.label}
        </NavDropdown.Item>))
    }

    handleLangChange = (locale) => {
        this.props.setLocale(locale);
    }

    render() {
        return (
            <header className="header" >
                <Navbar collapseOnSelect expand="lg" variant="dark">
                    <Navbar.Brand as={Link} to={this.props.generateUrl("/")}>
                        <img src={Logo} alt="Ajouve logo" className="header-logo" />
                        <p className="header-logo-title">{"Ajouve\r\nLimited"}</p>
                        <p className="header-logo-text">Make Great Business</p>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse  id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <Nav.Link eventKey="1" as={Link} to={this.props.generateUrl("/subforce")}>
                                <div className="mx-5">
                                    <p className={`header-company-link-name${this.props.location.pathname.endsWith("/subforce") ? " active" : ""}`}>Subforce</p>
                                    <p className="header-company-link-text"><FormattedMessage id={`header.subforce.subtitle`} /></p>
                                </div>
                            </Nav.Link>
                            <Nav.Link eventKey="2" as={Link} to={this.props.generateUrl("/de-neuville")}>
                                <div className="mx-5">
                                    <p className={`header-company-link-name${this.props.location.pathname.endsWith("/de-neuville") ? " active" : ""}`}>deNeuville</p>
                                    <p className="header-company-link-text"><FormattedMessage id={`header.deneuville.subtitle`} /></p>
                                </div>
                            </Nav.Link>
                            <Nav.Link eventKey="3" as={Link} to={this.props.generateUrl("/holoperf")}>
                                <div className="mx-5">
                                    <p className={`header-company-link-name${this.props.location.pathname.endsWith("/holoperf") ? " active" : ""}`}>Holoperf</p>
                                    <p className="header-company-link-text"><FormattedMessage id={`header.holoperf.subtitle`} /></p>
                                </div>
                            </Nav.Link>
                        </Nav>
                        <Nav className="ml-auto">
                            <Nav.Item>
                                <NavDropdown className='mt-lg-1 header-lang' title={
                                    <span className='header-lang-label' >{this.props.getLocale().toUpperCase()}</span>
                                } id="product-nav-dropdown">
                                    {this.renderMenuItems()}
                                </NavDropdown>
                            </Nav.Item>
                            <Nav.Link eventKey="4" as={Link} to={this.props.generateUrl("/contact")}>
                                <button className="header-contact-button"><FormattedMessage id="header.contact" /></button>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </header>
        );
    }

}

const mapStateToProps = state => ({
    generateUrl: generateUrl(state),
    getLocales: () => getLocales(state),
    getLocale: () => getLocale(state),
})

export default injectIntl(connect(mapStateToProps, {})(withRouter(Header)));