import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import langReducer from './reducers/lang'

export default function configureStore(preloadedState) {
  return createStore(
    langReducer,
    preloadedState,
    applyMiddleware(thunkMiddleware)
  )
}
