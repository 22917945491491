export const setLocale = locale => ({
    type: 'SET_LOCALE',
    locale
})

export const getLocale = lang => lang.locale;

export const getLocales = lang => lang.locales;

export const getMessages = lang => lang.messages;

export const generateUrl = lang => path => {
    return `/${lang.locale}${path}`
}
