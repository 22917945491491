import config from '../config';

function generateUrl(path) {
    let host = config.serverMessage.host;
    return `${host}${path}`
}

export function sendMessage(message) {
    return () => {
        return fetch(generateUrl('/message'), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message)
        })
            .then(response => {
                return response.json()
                    .then(json => {
                        if (!response.ok) {
                            throw new Error(json.message);
                        };
                        return json.message;
                    });
            })
    }
}