import React, { Component } from 'react'
import { Route, Redirect, Switch } from "react-router-dom";
import { connect } from 'react-redux'
import { IntlProvider } from "react-intl";
import { setLocale } from '../../actions/lang';
import Header from "../Header";
import Footer from "../Footer";
import Company from "../Company";
import Ajouve from "../Ajouve";
import Contact from "../Contact";
import Legal from "../Legal";
import companies from "../../data/companies.json";
import { Helmet } from "react-helmet";

class Lang extends Component {

    constructor(props) {
        super(props);
        this.state = {
            defaultLocale: navigator.language.split(/[-_]/)[0],
            locale: this.props.getLocale(),
            messages: this.props.getMessages()
        }
    }

    componentDidMount() {
        if (this.props.match.params.locale !== this.props.getLocale()) {
            this.setLocale(this.props.match.params.locale)
        }
    }

    setLocale(locale) {
        this.props.setLocale(locale);
        this.setState({
            locale: this.props.getLocale(),
            messages: this.props.getMessages()
        })
    }

    shouldChangeLocale() {
        return this.props.match.params.locale !== this.state.locale
    }

    render() {
        if (this.shouldChangeLocale()) return (<Redirect to={`/${this.state.locale}`} />);
        return (
            <div className="background-container" style={{ backgroundImage: !this.props.location.pathname.split('/')[2] ? "url(https://ajouve.s3.amazonaws.com/background.jpg)" : "url(https://ajouve.s3.amazonaws.com/background-alt.jpg)" }}>
                <IntlProvider locale={this.state.locale} messages={this.state.messages}>
                    <Header setLocale={this.setLocale.bind(this)} />
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Ajouve</title>
                        <link rel="canonical" href="http://ajouve.com" />
                        <meta name="description" content="Ajouve make great business" />
                    </Helmet>
                    <Switch>
                        <Route exact path="/:locale/subforce" >
                            <Company company={companies.subforce} />
                        </Route>
                        <Route exact path="/:locale/de-neuville" >
                            <Company company={companies.deneuville} />
                        </Route>
                        <Route exact path="/:locale/ijkoa" >
                            <Company company={companies.ijkoa} />
                        </Route>
                        <Route exact path="/:locale/holoperf" >
                            <Company company={companies.holoperf} />
                        </Route>
                        <Route exact path="/:locale/legal" >
                            <Legal type="legal"/>
                        </Route>
                        <Route exact path="/:locale/gcu" >
                            <Legal type="gcu"/>
                        </Route>
                        <Route exact path="/:locale/contact" >
                            <Contact/>
                        </Route>
                        <Route exact path="/:locale">
                            <Ajouve />
                        </Route>
                    </Switch>
                    <Footer />
                </IntlProvider>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({ getLocale: () => state.locale, getMessages: () => state.messages, getLocales: () => state.locales })

export default connect(mapStateToProps, { setLocale })(Lang);
