import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Helmet } from "react-helmet";
import Logo from '../img/logo.png'

class Company extends Component {

    render() {
        console.log(this.props);
        return (
            <div className={`company-element ajouve-element`}>
                <Helmet>
                    <title>{`${this.props.intl.formatMessage({ id: 'home.meta.title' })}`}</title>
                    <meta name="description" content={this.props.intl.formatMessage({ id: 'home.meta.description' })} />
                </Helmet>
                <div className="company-element-circle-container">
                    <div className="ajouve-text-container">
                        <div className="ajouve-text">
                            <img src={Logo} alt="AJOUVE LIMITED" className="img-fluid ajouve-logo" />
                            <p className="ajouve-logo-title">{`AJOUVE \r\nLIMITED`}</p>
                            <p className="ajouve-logo-text">
                                <FormattedMessage id={'company.ajouve.head.text'} />
                            </p>
                            <p className="ajouve-text" ><FormattedMessage id={`company.ajouve.text`} /></p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default injectIntl(Company)