import React, { Component } from 'react'
import { Redirect } from "react-router-dom";

export default class NoMatch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            locale: navigator.language.split(/[-_]/)[0]
        }
    }

    render() {
        return (<Redirect to={this.state.locale} />)
    }
}
