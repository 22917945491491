import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { sendMessage } from '../actions/message';
import { connect } from 'react-redux';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Helmet } from "react-helmet";

class Contact extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            message: '',
            token: '',
            step: 'waiting',
            errorInput: ''
        };
    }

    onContactChange(entity, event) {
        const update = {};
        update[entity] = event.target.value
        this.setState(update);
    }

    onContactSubmit() {
        //TODO : Add notification to get one when message sent
        let errorInput;
        if (!this.state.message || this.state.message === '')  {
            errorInput = "message"
        }
        if (!this.state.email || this.state.email === '')  {
            errorInput = "email"
        }
        if (!this.state.name || this.state.name === '')  {
            errorInput = "name"
        }
        if(errorInput) return this.setState({errorInput})
        return this.props.dispatch(sendMessage({
            name: `${this.state.lastName} ${this.state.firstName}`,
            email: this.state.email,
            content: this.state.message,
            subject: 'Contact Request',
            origin: 'ajouve',
            token: this.state.token
        }))
        .then(() => {
            return this.setState({ step: "success" })
        })
        .catch(err => {
            return this.setState({ step: "error" })
        })
    }

    renderMessage(){
        if (this.state.step === "success") return <FormattedMessage id="contact.success.message" />;
        if (this.state.step === "error") return <FormattedMessage id="contact.error.message" />;
        return;
    }

    renderSubmitButton(){
        if(this.state.step !== "waiting") return;
        return (
            <FormattedMessage id="contact.button">
                {value => <input type="submit" value={value} className="contact-form-button" onClick={this.onContactSubmit.bind(this)} />}
            </FormattedMessage>
        )
    }

    render() {
        return (
            <div className="contact-page">
                <Helmet>
                    <title>Ajouve Limited - Contact Form</title>
                </Helmet>
                <Helmet>
                    <title>{`${this.props.intl.formatMessage({ id: 'contact.meta.title' })}`}</title>
                    <meta name="description" content={this.props.intl.formatMessage({ id: 'contact.meta.description' })} />
                </Helmet>
                <div className="contact-container">
                    <div className="contact-title text-center">
                        <h1><FormattedMessage id="contact.title.form" /></h1>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6 offset-lg-3">
                                <div className="company-element-description">
                                    <div className="contact-form">
                                        <FormattedMessage id="contact.name.label">
                                            {placeholder => <input 
                                                className={`contact-form-input ${this.state.errorInput==="name" ? "contact-form-input-error" : ""}`}
                                                value={this.state.name} 
                                                onChange={this.onContactChange.bind(this, "name")} 
                                                placeholder={placeholder} required />}
                                        </FormattedMessage>
                                        <FormattedMessage id="contact.email.label">
                                            {placeholder => <input 
                                                className={`contact-form-input ${this.state.errorInput === "email" ? "contact-form-input-error" : ""}`}
                                                value={this.state.email} 
                                                onChange={this.onContactChange.bind(this, "email")} 
                                                placeholder={placeholder} type="email" required />}
                                        </FormattedMessage>
                                        <FormattedMessage id="contact.message.label">
                                            {placeholder => <textarea 
                                                className={`contact-form-textarea ${this.state.errorInput === "message" ? "contact-form-textarea-error" : ""}`}
                                                onChange={this.onContactChange.bind(this, "message")} 
                                                value={this.state.message} 
                                                placeholder={placeholder} required />}
                                        </FormattedMessage>
                                        {this.renderSubmitButton()}
                                        <div className="contact-message">{this.renderMessage()}</div>
                                        <GoogleReCaptchaProvider reCaptchaKey="6LddJXwUAAAAAH-LZMLNSvR-oakCnEXjUiysDlfO">
                                            <GoogleReCaptcha onVerify={token => this.setState({ token })} />
                                        </GoogleReCaptchaProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapDispatchToProps = dispatch => {
    return { dispatch };
}

export default injectIntl(connect(null, mapDispatchToProps)(Contact));