import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

class Legal extends Component {

    
    render() {

        return (
            <div>
                <div className="rectangle-title">
                    <h1>
                        <span className="font-weight-bold"><FormattedMessage id={`${this.props.type}.title`} /></span>
                    </h1>
                </div>
                <div className="rectangle-container">
                    <div className="rectangle">
                        <div dangerouslySetInnerHTML={{ __html: this.props.intl.formatMessage({id: `${this.props.type}.content`})}} />
                    </div>
                </div>
            </div>
        );
    }

}

export default injectIntl(Legal)